import { Injectable, NgZone, ApplicationRef, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import * as YAML from 'yaml';



@Injectable({
  providedIn: 'root'
})
export class FormService {

  input:string = "";
  yaml:any = {};
  error = "";
  root:any = false;
  jsonOutput:string = "";
  properties:any = {};
  
  constructor(private http: HttpClient) {

    this.input = localStorage.getItem("input");
    this.properties = {};
    this.http.get('assets/openapi.yaml', {    
        responseType: 'text'  
      }).subscribe(data => {
        this.yaml = YAML.parse(data);
        console.log(this.yaml);

        this.properties = {
          Grid: this.getDefaultProperties("Grid"),
          FormLabelField: this.getDefaultProperties("FormLabelField"),
          FormCalculatedLabelField: this.getDefaultProperties("FormCalculatedLabelField"),
          FormSingleLineField: this.getDefaultProperties("FormSingleLineField"),
          FormDateField: this.getDefaultProperties("FormDateField"),
          FormDateTimeField: this.getDefaultProperties("FormDateTimeField"),
          FormTimeField: this.getDefaultProperties("FormTimeField"),
          FormDurationField: this.getDefaultProperties("FormDurationField"),
          FormIntegerInputField: this.getDefaultProperties("FormIntegerInputField"),
          FormDoubleInputField: this.getDefaultProperties("FormDoubleInputField"),
          FormMultiLineField: this.getDefaultProperties("FormMultiLineField"),
          FormHeaderField: this.getDefaultProperties("FormHeaderField"),
          FormCheckboxField: this.getDefaultProperties("FormCheckboxField"),
          FormRadioButtonField: this.getDefaultProperties("FormRadioButtonField"),
          FormSingleSelectField: this.getDefaultProperties("FormSingleSelectField"),
          FormMultiSelectField: this.getDefaultProperties("FormMultiSelectField"),
          FormImageAttachmentField: this.getDefaultProperties("FormImageAttachmentField"),
          FormHandwrittenField: this.getDefaultProperties("FormHandwrittenField"),
          FormSelectOptionGroup: this.getDefaultProperties("FormSelectOptionGroup"),
          SelectOption: this.getDefaultProperties("SelectOption")
        }
    });


  }

  private saveToStorage() {
    localStorage.setItem("input", this.input);
  }

  public generateFromInput() {
    this.error = '';

    this.saveToStorage();


    try {
      this.root = JSON.parse(this.input);
      if (!this.root) throw new Error("Empty input");
      if (!Array.isArray(this.root.grids)) throw new Error("Cannot find GRIDS element at file root");

    } catch(error) {
      this.root = false;
      this.error = error.message;
    }
  }

  public updateInput () {
    if (this.root) {
      this.root.title = this.root.title.trim();
      this.input = JSON.stringify(this.root, null, 2);
      this.saveToStorage();
    }
  }

  public loadForm(tpl) {
    this.http.get('assets/'+tpl+'.json', {  
      headers: new HttpHeaders()  
        .set('Content-Type', 'text/plain')  
        .append('Access-Control-Allow-Methods', 'GET')  
        .append('Access-Control-Allow-Origin', '*')  
        .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),  
        responseType: 'text'  
      }).subscribe(data => {
        this.input = data;
        this.generateFromInput();
    });

  }

  public getDefaultProperties (type: string) {

    function mergePropertiesAndRequired (properties, required) {
      let output = {};
      for(let id in properties) {
        if (id == "type" || id == "row" || id == "column" || id == "columnDefinitions" || id == "rowDefinitions" || id == "selectOption" || id == "selectOptionGroup") continue;
        output[id] = { 
          ...properties[id], 
          required: Array.isArray(required)? required.includes(id) : false,
          id: id
        };
        if (output[id].default === undefined) {
          switch(output[id].type) {
            case "string": output[id].default = ""; break;
            case "boolean": output[id].default = false; break;
            case "array": output[id].default = []; break;
            case "integer": output[id].default = ""; break;
            case "number": output[id].default = ""; break;
            case "date": output[id].default = ""; break;
            case "date-time": output[id].default = ""; break;
            case "time": output[id].default = ""; break;
            case "array": output[id].default = []; break;
            default: output[id].default = ""; break;
          }
        }
      }
      return output;
    }

    try {
      let props = {};

      if (this.yaml.components.schemas[type].allOf) {
        for (let el of this.yaml.components.schemas[type].allOf) {
          if (el.$ref && el.$ref == "#/components/schemas/Field") {
            props = { ...props, ...mergePropertiesAndRequired(this.yaml.components.schemas.Field.properties, this.yaml.components.schemas.Field.required) }

          } else if (el.properties) {
            props = { ...props, ...mergePropertiesAndRequired(el.properties, this.yaml.components.schemas[type].required) }

          } else {
            throw new Error();
          }

        }
      } else {
        props = { ...mergePropertiesAndRequired(this.yaml.components.schemas[type].properties, this.yaml.components.schemas[type].required) }
      }

      return props;

    } catch (e) {
      console.log(e);
      console.log("Unable to get properties for field type "+type);
    }

  }



  public initElement(element: any, type: string) {

    // add reference to type sinc not all elements have a type property in SAML definition
    element._getType = function() { return type };

    // clean up properties
    this.cleanUpProperties(element);

    let form = this;

    element._get = function(name: string) {
      if (form.properties[type] == undefined) {
        console.log("Accessing property of unknown field type "+type);
        return undefined;
      }
      let prop = form.properties[type][name];
      if (!prop) {
        return null;
      }
      if (element[name] === undefined) return prop.default;
      else return element[name];
    }

    element._set = function(name: string, value) {
      if (form.properties[type] == undefined) {
        console.log("Setting property of unknown field type "+type);
        return element;
      }
      let prop = form.properties[type][name];
      if (!prop) {
        return element;
      }
      if (prop.default === value && !prop.required) delete element[name];
      else element[name] = value;
    }
    return element;
  }

  public generateId() {
    while (true) {
      let id = '_'+ Math.random().toString(36).substring(2, 15);
      if (!this.findFieldById(id)) return id;
    }
  }

  public findFieldById(id: string) {
    if (!id) return undefined;

    for (let grid of this.root.grids) {
      for (let field of grid.fields) {
        if (field.id == id) return field;
      }
    }
    return false;
  }

  public cleanUpProperties(element: any) {
    let properties = this.properties[element._getType()];
    if (!properties) {
      console.log("Unknown element type: "+element._getType());
      return;
    }
    // remove any optional fields that are equal to default
    for (let prop in element) {
      if (properties[prop]) {
        if (!properties[prop].required && properties[prop].default === element[prop]) {
          delete element[prop];
        } else if (prop == "id" && element[prop] == "") {
          element[prop] = this.generateId();
        }
      }
    }
    // add any missing required field and set to default
    for (let prop in properties) {
      if (!element[prop] && properties[prop].required) {
        element[prop] = properties[prop].default;
      }
    }
  }


  public moveGrid(grid: any, step: number) {
    for(let i in this.root.grids) {
      if (this.root.grids[i] === grid) {
        let grid = this.root.grids[i];
        this.root.grids.splice(i, 1);
        this.root.grids.splice(i+step, 0, grid)
        return;
      }
    }
  }

  public addGrid() {
    this.root.grids.push({
      columnDefinitions: [{width: 100}],
      rowDefinitions: [{height: 45}],
      fields: []
    });
  }

  public addGridBefore(grid: any) {
    for(let i in this.root.grids) {
      if (this.root.grids[i] === grid) {
        this.root.grids.splice(i, 0, {
          columnDefinitions: [{width: 100}],
          rowDefinitions: [{height: 45}],
          fields: []
        });
        break;
      }
    }
  }

  public deleteGrid(grid: any) {
    for(let i in this.root.grids) {
      if (this.root.grids[i] === grid) {
        this.root.grids.splice(i, 1);
        break;
      }
    }
  }




}
