<ng-template #popContent let-element="element">
  <ng-container *ngIf="element">
    <button class="btn btn-primary" (click)="modal.open()">Edit</button>&nbsp;
    <button class="btn btn-primary" (click)="deleteElement(element)">Delete</button>
  </ng-container>
  <app-modal #modal [element]="element"></app-modal>
</ng-template>

<ng-template #selector let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{element._get("title")}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body list" cdkDropList (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let group of element.selectOptionGroup; index as i">
      <div cdkDrag cdkDragDisabled 
        class="group"
        [ngbPopover]="popContent"
        #p1="ngbPopover"
        [placement]="i == 0? 'bottom':'auto'"
        triggers="manual"
        (contextmenu)="openPopover(p1, group)"
        container="body"

      >
        {{group._get('text') || ''}}
      </div>
      <button *ngFor="let option of group.selectOption"
        [ngbPopover]="popContent"
        (click)="clickOption(option)" 
        [ngClass]="{'option' : true, 'box': true, 'selected': option._get('selected') == true}"
        #p1="ngbPopover"
        (contextmenu)="openPopover(p1, option)"
        cdkDrag
        triggers="manual"
        cdkDragBoundary=".modal-body"
        (mousedown)="p1.close();"
        container="body"

      >{{option._get('text')}}</button>
    </ng-container>
    <div style="text-align: center; width: 100%;">
      <button type="btn" class="btn confirm" (click)="modal.close();">CONFIRM</button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addGroup();">Add Group</button>
    <button type="button" class="btn btn-primary" (click)="addOption();" [disabled]="element.selectOptionGroup.length == 0">Add Option</button>
  </div>
</ng-template>



<div class="input" (click)="open(selector)">
  <ng-container *ngFor="let group of element.selectOptionGroup;">
    <ng-container *ngFor="let option of group.selectOption; last as last">
      <ng-container *ngIf="option._get('selected')">
        <span>{{option._get('text')}}</span>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="icon">
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
