
<ng-template #popContent let-field="field" let-row="row" let-column="column" let-tt="tt">
  <ng-container *ngIf="field">
    <button class="btn btn-primary" (click)="tt.close();modal.open();">Edit</button>&nbsp;
    <button class="btn btn-primary" (click)="tt.close();deleteField(field);">Delete</button>&nbsp;
  </ng-container>
  <ng-container *ngIf="!field && row != -1">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-primary" id="dropdownAdd" ngbDropdownToggle>Add</button>&nbsp;
      <div ngbDropdownMenu aria-labelledby="dropdownAdd">
        <ng-container *ngFor="let name of form.properties | keyvalue">
          <button *ngIf="name.key != 'Grid' && name.key != 'SelectOption' && name.key != 'FormSelectOptionGroup'" ngbDropdownItem (click)="add(name.key, row, column);">{{name.key}}</button>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div ngbDropdown class="d-inline-block">
    <button class="btn btn-primary" id="dropdownGrid" ngbDropdownToggle>Grid</button>
    <div ngbDropdownMenu aria-labelledby="dropdownGrid">
      <button ngbDropdownItem (click)="tt.close();gridmodal.open();">Edit</button>
      <button ngbDropdownItem (click)="tt.close();addRow(row);">Add Row Below</button>
      <button ngbDropdownItem (click)="tt.close();addColumn(column);">Add Column Right</button>
      <button *ngIf="row != -1 && rows.length > 1" ngbDropdownItem (click)="tt.close();deleteRow(row);">Delete Row</button>
      <button *ngIf="column != -1 && columns.length > 1" ngbDropdownItem (click)="tt.close();deleteColumn(column);">Delete Column</button>
      <button ngbDropdownItem (click)="tt.close();move(-1);">Move Grid Up</button>
      <button ngbDropdownItem (click)="tt.close();move(1);">Move Grid Down</button>
      <button ngbDropdownItem (click)="tt.close();addGrid();">Add Grid Above</button>
      <button ngbDropdownItem (click)="tt.close();delete();">Delete Grid</button>
    </div>
  </div>
  <app-modal #modal [element]="field"></app-modal>
</ng-template>

<app-modal #gridmodal [element]="grid"></app-modal>


<div *ngIf='grid._get("gridHeader")' 
  class="header"
  [ngbPopover]="popContent"
  container="body"
  [popoverTitle]="'Grid'"
  triggers="manual"
  #p1="ngbPopover"
  [autoClose]="'outside'"
  (contextmenu)="openPopover(p1, null, -1, -1)"
>{{grid._get("gridHeader")}}</div>
<table>
  <tr *ngFor="let cols of cells; let r = index" [ngStyle]="{'height': rows[r].height+'px'}">
    <td cellpadding=0 *ngFor="let field of cols; let c = index" 
      [ngbPopover]="popContent"
      container="body"
      [popoverTitle]="field? field._getType() : 'Empty'"
      triggers="manual"
      #p1="ngbPopover"
      [autoClose]="'outside'"
      (contextmenu)="openPopover(p1, field, r, c)"
      [ngStyle]="{
        'width': columns[c].width+'%',
        'text-align': getFieldHorizontalAlign(field), 
        'vertical-align': getFieldVerticalAlign(field),
        'height': rows[r].height+'px'
      }"
    >
      <app-ab-any *ngIf="field" [element]="field"></app-ab-any>
      <div *ngIf="!field && grid.fields.length == 0" style="font-style: italic;   height: 1em; font-size: 80%;">[ Right click to add at least one element to Grid ]</div>
      <div 
        class="resize-height" 
        (mousedown)="resizeHeight($event, r, ttH)" 
        #ttH="ngbTooltip" 
        triggers="manual" 
        [ngbTooltip]="ttHeight" 
        container="body"
      ></div>
      <ng-template #ttHeight>{{rows[r].height}}px</ng-template>
      <div 
        class="resize-width" 
        (mousedown)="resizeWidth($event, c, ttW)" 
        #ttW="ngbTooltip" 
        [ngbTooltip]="ttWidth" 
        triggers="manual" 
        container="body"
      ></div>
      <ng-template #ttWidth>{{columns[c].width}}%</ng-template>
    </td>
  </tr>
</table>




