import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ab-integer-input',
  templateUrl: './ab-integer-input.component.html',
  styleUrls: ['./ab-integer-input.component.scss']
})
export class AbIntegerInputComponent implements OnInit {

 @Input() element: any;
  constructor() { }

  ngOnInit(): void {
  }

}
