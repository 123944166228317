import { Component, OnInit, Input } from '@angular/core';
import { FormService } from "../form.service";

@Component({
  selector: 'app-ab-calculated-label',
  templateUrl: './ab-calculated-label.component.html',
  styleUrls: ['./ab-calculated-label.component.scss']
})
export class AbCalculatedLabelComponent implements OnInit {

 @Input() element: any;
  constructor(public form:FormService) { }

  ngOnInit(): void {
  }

  value() : string { 

    try {
      let left = this.form.findFieldById(this.element._get("leftOperand"))._value;
      let right = this.form.findFieldById(this.element._get("rightOperand"))._value;

      if (left !== undefined && right !== undefined && left !== "" && right !== "") {
        left = parseFloat(left);
        right = parseFloat(right);
        switch(this.element._get("operator")) {
          case "ADDITION": return (left+right).toString();
          case "SUBTRACTION": return (left-right).toString();
          case "MULTIPLICATION": return (left*right).toString();
          case "DIVISION": return (left/right).toString();
        }
      }
    } catch(error) {
    }
    return this.element._get("text");

  }
}
