import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ab-multi-line',
  templateUrl: './ab-multi-line.component.html',
  styleUrls: ['./ab-multi-line.component.scss']
})
export class AbMultiLineComponent implements OnInit {

  constructor() { }

 @Input() element: any;
  ngOnInit(): void {
  }

}
