
<header>
  <h2>AvioBook Online Form Editor v2</h2>
  Instructions:
    <ol>
      <li>Import existing JSON form in the first tab or click new to create a new form</li>
      <li>Visualize form in the second tab. Right click on any elements to add/edit/remove. Click and drag grid cell borders to resize.</li>
      <li>Change form revision number. Download JSON in first tab and upload in Base.</li>
    </ol>
    This standalone tool is not supported by AvioBook and is provided as-is. Tested on Google Chrome only. Use at your own risk. Contact loic.minghetti@aviobook.aero for more information.
<br/>
<br/>

</header>

<ngb-tabset [destroyOnHide]="false" (tabChange)="beforeChange($event)">
  <ngb-tab id="raw" title="JSON">
    <ng-template ngbTabContent>
      <button class="btn btn-outline-primary" (click)="form.loadForm('blank')">New</button>&nbsp;
      <button class="btn btn-outline-primary" (click)="form.loadForm('sample')">New from demo tpl</button>
      &nbsp;
      <a class="btn btn-outline-primary" #dlbtn (click)="download(dlbtn);" href="#">Download JSON</a>
      <br/>
      <br/>
      <div style="">
      <ngx-codemirror
        [(ngModel)]="form.input"
        [options]="{
          lineNumbers: true,
          theme: 'material',
          mode: {name: 'javascript', json: true}
        }"
      ></ngx-codemirror></div>

    </ng-template>
  </ngb-tab>
  <ngb-tab id="editor" title="Editor">
    <ng-template ngbTabContent>

      <div *ngIf="!form.root || form.error != ''">
        <ngb-alert [dismissible]="false" type="danger">
          Unable to read JSON ({{form.error}})
        </ngb-alert>
        <p>Make sure JSON is valid according to Forms YAML.</p>
        <p>Click New to start a new Form from scratch.</p>
        <br/>
        <button class="btn btn-outline-primary" (click)="form.loadForm('blank')">New</button>
      </div>

      <div *ngIf="form.root && form.error == ''" ng-cloak>
        <div class="container">
          <div class="row">
            <div class="col-sm">
              Title: 
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              <input type="text" class="form-control" [(ngModel)]="form.root.title" maxlength="50">
            </div>
          </div>
        </div>
        <br/>
        <div id="form-visual-editor">
          <app-ab-grid *ngFor="let grid of form.root.grids" [grid]="grid"></app-ab-grid>
        </div>
        <br/>
        <button class="btn btn-outline-primary" (click)="addGrid();">Add Grid</button>
      </div>

    </ng-template>
  </ngb-tab>
</ngb-tabset>