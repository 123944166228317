import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ab-single-line',
  templateUrl: './ab-single-line.component.html',
  styleUrls: ['./ab-single-line.component.scss']
})
export class AbSingleLineComponent implements OnInit {

 @Input() element: any;
  constructor() { }

  ngOnInit(): void {
  }

}
