import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ab-double-input',
  templateUrl: './ab-double-input.component.html',
  styleUrls: ['./ab-double-input.component.scss']
})
export class AbDoubleInputComponent implements OnInit {

 @Input() element: any;
  constructor() { }

  ngOnInit(): void {
  }


  public valid(value) {
    if (value === "") return true;
    console.log(value);
    console.log(value.match(/^-?\d*(\.\d+)?$/));
    if (value.match(/^-?\d*(\.\d+)?$/) === null) return false;
    value = parseFloat(value);
    if (this.element._get("minValue") !== "" && value < this.element._get("minValue")) return false;
    if (this.element._get("minValue") === "" && !this.element._get("allowNegative") && value < 0) return false;
    if (this.element._get("maxValue") !== "" && value > this.element._get("maxValue")) return false;
    return true;
  }
}
