import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormService } from '../form.service';
import {FormControl} from '@angular/forms';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  modal;

 @Input() element: any;
 @ViewChild('content', { static: true }) content: ElementRef;

  constructor(private modalService: NgbModal, public form: FormService) { }

  ngOnInit(): void {
  }

  open() {
    this.modal = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((form) => {
      for(let input of form.elements) {
        if (input.type == "checkbox") {
          this.element._set(input.id, input.checked);
        } else {
          this.element._set(input.id, input.value);
        }
      }
    }, (reason) => {});
  }
}
