import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { FormService } from '../form.service';

@Component({
  selector: 'app-ab-grid',
  templateUrl: './ab-grid.component.html',
  styleUrls: ['./ab-grid.component.scss']
})
export class AbGridComponent implements OnInit {

 @Input() grid: any;

  cells = [];
  rows = [];
  columns = [];

  constructor(public form: FormService, private renderer: Renderer2) {
  }

  ngOnInit(): void {

    this.rows = this.grid.rowDefinitions;
    this.columns = this.grid.columnDefinitions;

    for (let i = 0; i < this.rows.length; i++) {
      this.cells[i] = [];
      for (let j = 0; j < this.columns.length; j++) {
        this.cells[i][j] = false;
      }
    }


    for(let field of this.grid.fields) {
      field.row = parseInt(field.row);
      field.column = parseInt(field.column);
      if (!this.cells[field.row] || this.cells[field.row][field.column] === undefined) {
        console.log("Skipped cell field of type "+field.type+" because outside of grid");
        continue;
      }
      this.cells[field.row][field.column] = field;
      this.form.initElement(field, field.type);
    }

    this.adjustWidth();
    this.form.initElement(this.grid, "Grid");
  }

  public adjustWidth() {
    // try to fix any wrong col width
    try {
      let delta = 100;
      for (let j = 0; j < this.columns.length; j++) {
        if (this.columns[j].width < 5) {
          this.columns[j].width = 5;
        }
        delta -= this.columns[j].width;
      }
      if (delta != 0) {
        for (let j = this.columns.length-1; j >= 0; j--) {
          let width = this.columns[j].width;
          let newWidth = width+delta;
          if (newWidth < 5) newWidth = 5;
          delta = delta + width - newWidth;
          this.columns[j].width = newWidth;
          if (delta == 0) break;
        }
      }
    } catch(e) {}
  }

  public deleteField (field) {
    for(let i in this.grid.fields) {
      if (this.grid.fields[i] === field) {
        this.grid.fields.splice(i, 1);
      } 
    }
    this.cells[field.row][field.column] = false;
  }


  public delete() {
    this.form.deleteGrid(this.grid);
  }

  public deleteRow(row) {
    this.rows.splice(row, 1);
    this.cells.splice(row, 1);
  }

  public deleteColumn(column) {
    this.columns.splice(column, 1);
    for (let cellRow of this.cells) {
      cellRow.splice(column, 1);
    }
    this.adjustWidth();
  }

  public addRow(row) {
    row = parseInt(row);
    this.rows.splice(row+1, 0, {height: 45});
    this.cells.splice(row+1, 0, []);
    for (let i = 0; i < this.columns.length; i++) {
      this.cells[row+1][i] = false;
    }
    for (let field of this.grid.fields) {
      if (field.row > row) field.row = field.row + 1;
    }
  }

  public addColumn(column) {
    column = parseInt(column);
    this.columns.splice(column+1, 0, {width: 5});
    for (let i = 0; i < this.cells.length; i++) {
      this.cells[i].splice(column+1, 0, false);
    }
    for (let field of this.grid.fields) {
      if (field.column > column) field.column = field.column + 1;
    }
    this.adjustWidth();
  }

  public add(type: string, row: number, column: number) {
    let field:any = {}

    if (!this.form.properties[type]) throw "Unknown field type: "+type;
    for (let prop in this.form.properties[type]) {
      if (this.form.properties[type][prop].required) {
        field[prop] = this.form.properties[type][prop].default;
      }
    }
    this.form.initElement(field, type);
    field.type = type;
    field.column = column;
    field.row = row;
    this.cells[row][column] = field;
    this.grid.fields.push(field);
  }


  public addGrid() {
    this.form.addGridBefore(this.grid);
  }

  public move(step) {
    this.form.moveGrid(this.grid, step);
  }


  lastPopover: any;
  openPopover(popover, field, row, column) {
    if (this.lastPopover) this.lastPopover.close();
    this.lastPopover = popover;
    popover.open({field: field, row: row, column: column, tt: popover});
    return false;
  }

  getFieldVerticalAlign (field) {
    if (!field) return "MIDDLE";
    if (field._get("verticalTextAlignment") == 'CENTER') return 'MIDDLE';
    return field._get("verticalTextAlignment") || 'MIDDLE';
  }

  getFieldHorizontalAlign (field) {
    if (!field) return "LEFT";
    return field._get("horizontalTextAlignment") || 'LEFT';
  }





  pressed: boolean = false;
  startX: any;
  startY: any;
  lastX: any;
  lastY: any;
  startWidth: any;
  parentWidth: any;
  originalHeight: any;
  originalPercent: any;
  nextOriginalPercent: any;
  resizableFnMousemove: () => void;
  resizableFnMouseup: () => void;

  resizeWidth(event: any, column: any, tt) {
    column = parseInt(column);
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    this.parentWidth = event.target.parentElement.parentElement.clientWidth;
    this.originalPercent = parseInt(this.columns[column].width);
    this.nextOriginalPercent = parseInt(this.columns[column+1].width);
    tt.open();
    this.mouseMoveX(column, tt);
  }

  mouseMoveX(column: any, tt) {
    this.resizableFnMousemove = this.renderer.listen('document', 'mousemove', (event) => {
      if (this.pressed) {
        if (Math.abs(event.pageX - this.lastX) == 0) return;
        this.lastX = event.pageX;
        
        let deltaPercent = Math.round(100 * (event.pageX - this.startX) / this.parentWidth)

        if (this.originalPercent+deltaPercent < 5) deltaPercent = 5 - this.originalPercent;
        if (this.nextOriginalPercent-deltaPercent < 5) deltaPercent = this.nextOriginalPercent-5

        this.columns[column].width = this.originalPercent+deltaPercent;
        this.columns[column+1].width = this.nextOriginalPercent-deltaPercent;
      }
    });
    this.resizableFnMouseup = this.renderer.listen('document', 'mouseup', (event) => {
      if (this.pressed) {
        tt.close();
        this.pressed = false;
        this.resizableFnMousemove();
        this.resizableFnMouseup();
      }
    });
  };


  resizeHeight(event: any, row: any, tt: any) {
    row = parseInt(row);
    this.originalHeight = parseInt(this.rows[row].height);
    this.pressed = true;
    this.startY = event.pageY;
    tt.open();
    this.mouseMoveY(row, tt);
  }

  mouseMoveY(row: any, tt: any) {
    this.resizableFnMousemove = this.renderer.listen('document', 'mousemove', (event) => {
      if (this.pressed) {
        if (Math.abs(event.pageY - this.lastY) == 0) return;
        this.lastY = event.pageY;
        let delta = event.pageY - this.startY;
        if (this.originalHeight + delta < 45) delta = 45 - this.originalHeight;

        this.rows[row].height = (this.originalHeight + delta);
      }
    });
    this.resizableFnMouseup = this.renderer.listen('document', 'mouseup', (event) => {
      if (this.pressed) {
        tt.close();
        this.pressed = false;
        this.resizableFnMousemove();
        this.resizableFnMouseup();
      }
    });
  };


}
