
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{element._getType()}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #inputForm>
      <div class="form-group">
        

        <ng-container *ngFor="let prop of form.properties[element._getType()] | keyvalue">
          <div class="form-group">
            <label [for]="prop.value.id">{{prop.value.id}}</label>
            <input *ngIf="!prop.value.enum && (prop.value.type=='string' || prop.value.type=='date' || prop.type=='time' || prop.value.type=='date-time')" [id]="prop.value.id" class="form-control" placeholder="{{prop.value.default}}" [value]="element._get(prop.value.id)" [attr.maxlength]="prop.value.maxLength || ''">

            <input *ngIf="!prop.value.enum && prop.value.type=='integer'" type="number" [id]="prop.value.id" class="form-control" placeholder="{{prop.value.default}}" [value]="element._get(prop.value.id)">
            
            <input *ngIf="!prop.value.enum && prop.value.type=='number'" step="any" type="number" [id]="prop.value.name" class="form-control" placeholder="{{prop.value.default}}" [value]="element._get(prop.value.id)">

            <select *ngIf="prop.value.enum" [id]="prop.value.id" class="form-control" placeholder="">
              <option *ngFor="let option of prop.value.enum" [selected]="element._get(prop.value.id) == option">{{option}}</option>
            </select>

            <input *ngIf="!prop.value.enum && prop.value.type=='boolean'" type="checkbox" [id]="prop.value.id" class="form-control" [checked]="element._get(prop.value.id)">

<!--             <ng-container *ngIf="attr.type=='autocomplete'">
              <input type="text" [id]="attr.name" class="form-control" placeholder="{{attr.default}}" [value]="element._get(attr.name)" [attr.maxlength]="attr.maxLength || ''" [attr.list]="attr.name+'_list'"/>
              <datalist [id]="attr.name+'_list'">
                <option *ngFor="let option of attr.options | keyvalue" value="{{option.key}}">{{option.value}}</option>
              </datalist>
            </ng-container> -->

          </div>


        </ng-container>




      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close(inputForm);">Save</button>
  </div>
</ng-template>
