
<ng-container [ngSwitch]="element._getType()">
  <ng-container *ngSwitchCase="undefined"></ng-container>
  <app-ab-label *ngSwitchCase="'FormLabelField'" [(element)]="element"></app-ab-label>
  <app-ab-calculated-label *ngSwitchCase="'FormCalculatedLabelField'" [(element)]="element"></app-ab-calculated-label>
  <app-ab-single-line *ngSwitchCase="'FormSingleLineField'" [(element)]="element"></app-ab-single-line>
  <app-ab-date *ngSwitchCase="'FormDateField'" [(element)]="element"></app-ab-date>
  <app-ab-date-time *ngSwitchCase="'FormDateTimeField'" [(element)]="element"></app-ab-date-time>
  <app-ab-time *ngSwitchCase="'FormTimeField'" [(element)]="element"></app-ab-time>
  <app-ab-duration *ngSwitchCase="'FormDurationField'" [(element)]="element"></app-ab-duration>
  <app-ab-integer-input *ngSwitchCase="'FormIntegerInputField'" [(element)]="element"></app-ab-integer-input>
  <app-ab-double-input *ngSwitchCase="'FormDoubleInputField'" [(element)]="element"></app-ab-double-input>
  <app-ab-multi-line *ngSwitchCase="'FormMultiLineField'" [(element)]="element"></app-ab-multi-line>
  <app-ab-handwritten *ngSwitchCase="'FormHandwrittenField'" [(element)]="element"></app-ab-handwritten>
  <app-ab-header *ngSwitchCase="'FormHeaderField'" [(element)]="element"></app-ab-header>
  <app-ab-check-box *ngSwitchCase="'FormCheckboxField'" [(element)]="element"></app-ab-check-box>
  <app-ab-radio-button *ngSwitchCase="'FormRadioButtonField'" [(element)]="element"></app-ab-radio-button>
  <app-ab-select *ngSwitchCase="'FormSingleSelectField'" [(element)]="element"></app-ab-select>
  <app-ab-select *ngSwitchCase="'FormMultiSelectField'" [(element)]="element"></app-ab-select>
  <app-ab-image-attachment *ngSwitchCase="'FormImageAttachmentField'" [(element)]="element"></app-ab-image-attachment>
  <app-ab-unknown *ngSwitchDefault [(element)]="element"></app-ab-unknown>
</ng-container>



