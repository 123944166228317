import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormService } from './form.service';
import {NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(public form: FormService) {

  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'raw') {
      this.form.updateInput();
    } else if ($event.nextId === 'editor') {
      this.form.generateFromInput();
    }
  }


  public addGrid() {
    this.form.addGrid();
  }

  public download(button) {
    button.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(this.form.input));
    button.setAttribute('download', (this.form.root.title? this.form.root.title:"Form")+'.json');
    return true;
  }

}

