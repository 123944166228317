import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ab-handwritten',
  templateUrl: './ab-handwritten.component.html',
  styleUrls: ['./ab-handwritten.component.scss']
})
export class AbHandwrittenComponent implements OnInit {

  constructor() { }

 @Input() element: any;
  ngOnInit(): void {
  }

}
