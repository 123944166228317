import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ab-header',
  templateUrl: './ab-header.component.html',
  styleUrls: ['./ab-header.component.scss']
})
export class AbHeaderComponent implements OnInit {

 @Input() element: any;

  constructor() { }

  ngOnInit(): void {
  }

}
