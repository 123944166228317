import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { NgModule, ErrorHandler, Injector } from '@angular/core';

import { AppComponent } from './app.component';
import { AbLabelComponent } from './ab-label/ab-label.component';
import { AbUnknownComponent } from './ab-unknown/ab-unknown.component';
import { AbAnyComponent } from './ab-any/ab-any.component';
import { AbGridComponent } from './ab-grid/ab-grid.component';
import { AbHeaderComponent } from './ab-header/ab-header.component';
import { AbSingleLineComponent } from './ab-single-line/ab-single-line.component';
import { ModalComponent } from './modal/modal.component';
import { AbMultiLineComponent } from './ab-multi-line/ab-multi-line.component';
import { AbHandwrittenComponent } from './ab-handwritten/ab-handwritten.component';
import { AbIntegerInputComponent } from './ab-integer-input/ab-integer-input.component';
import { AbDoubleInputComponent } from './ab-double-input/ab-double-input.component';
import { AbDateComponent } from './ab-date/ab-date.component';
import { AbDateTimeComponent } from './ab-date-time/ab-date-time.component';
import { AbTimeComponent } from './ab-time/ab-time.component';
import { AbDurationComponent } from './ab-duration/ab-duration.component';
import { AbImageAttachmentComponent } from './ab-image-attachment/ab-image-attachment.component';
import { AbCalculatedLabelComponent } from './ab-calculated-label/ab-calculated-label.component';
import { AbCheckBoxComponent } from './ab-check-box/ab-check-box.component';
import { AbRadioButtonComponent } from './ab-radio-button/ab-radio-button.component';
import { AbSelectComponent } from './ab-select/ab-select.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

import { HttpClientModule } from '@angular/common/http';

import { FormService } from './form.service';

import { ErrorHandlerService } from './error-handler.service';

 

@NgModule({
  declarations: [
    AppComponent,
    AbLabelComponent,
    AbUnknownComponent,
    AbAnyComponent,
    AbGridComponent,
    AbHeaderComponent,
    AbSingleLineComponent,
    ModalComponent,
    AbMultiLineComponent,
    AbHandwrittenComponent,
    AbIntegerInputComponent,
    AbDoubleInputComponent,
    AbDateComponent,
    AbDateTimeComponent,
    AbTimeComponent,
    AbDurationComponent,
    AbImageAttachmentComponent,
    AbCalculatedLabelComponent,
    AbCheckBoxComponent,
    AbRadioButtonComponent,
    AbSelectComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    DragDropModule,
    CodemirrorModule,
    HttpClientModule
  ],
  providers: [
    {provide: ErrorHandler, useClass: ErrorHandlerService}
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }


 