import { Injectable, ErrorHandler, NgZone, ApplicationRef, Injector } from '@angular/core';
import { FormService } from './form.service';



@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  static canTick:boolean = true;

  constructor(private form: FormService, private zone:NgZone, private injector: Injector) { }

  handleError(error) {
    console.log("error", error);
    this.form.root = false;
    this.form.error = error;
    // only tick once to avoid loops
    if (ErrorHandlerService.canTick) {
      ErrorHandlerService.canTick = false;
      setTimeout(()=>{
        ErrorHandlerService.canTick = true;
      }, 1000);
      setTimeout(()=>{
        try {
        this.injector.get(ApplicationRef).tick();
        } catch(e) {}
      }, 0);
    }
  }
}
