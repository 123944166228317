import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormService } from '../form.service';


@Component({
  selector: 'app-ab-select',
  templateUrl: './ab-select.component.html',
  styleUrls: ['./ab-select.component.scss']
})
export class AbSelectComponent implements OnInit {

 @Input() element: any;


  lastPopover;
  constructor(private modalService: NgbModal, public form:FormService) { }

  ngOnInit(): void {
    if (!this.element.selectOptionGroup) this.element.selectOptionGroup = [];
    for(let group of this.element.selectOptionGroup) {
      this.form.initElement(group, "FormSelectOptionGroup");
      if (!group.selectOption) group.selectOption = [];
      for (let option of group.selectOption) {
        this.form.initElement(option, "SelectOption");
      }
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'optionmodal' , scrollable: true}).result.then((result) => {
      //
    }, (reason) => {
      //
    });
  }

  addGroup() {
    let group = {
      id: this.form.generateId(),
      text: "New Group",
      selectOption: []
    }
    this.form.initElement(group, "FormSelectOptionGroup");
    this.element.selectOptionGroup.push(group);
  }

  addOption() {
    let option = {
      id: this.form.generateId(),
      text: "New Option"
    };
    this.form.initElement(option, "SelectOption");
    let group = this.element.selectOptionGroup[this.element.selectOptionGroup.length-1];
    group.selectOption.push(option);
  }

  public clickOption(option) {
    if (this.element._getType() == 'FormSingleSelectField') {
      let state = option._get('selected');
      for(let g of this.element.selectOptionGroup) {
        for (let o of g.selectOption) {
          o._set('selected', false);
        }
      }
      option._set('selected', !state);
    } else {
      option._set('selected', !option._get('selected'));
    }
  }


  openPopover(popover, element) {
    if (this.lastPopover) this.lastPopover.close();
    this.lastPopover = popover;
    popover.open({element: element});
    return false;
  }


  public deleteElement (element) {
    for(let key in this.element.selectOptionGroup) {
      for (let key2 in this.element.selectOptionGroup[key].selectOption) {
        if (this.element.selectOptionGroup[key].selectOption[key2] === element) {
          this.element.selectOptionGroup[key].selectOption.splice(key2, 1);
        } 
      }
      if (this.element.selectOptionGroup[key] === element) {
        this.element.selectOptionGroup.splice(key, 1);
      }
    }
  }


  drop(event: CdkDragDrop<string[]>) {
    let prev = event.previousIndex;
    let next = event.currentIndex;
    if (next == 0) next = 1;
    if (prev == next) return;
    let option;
    let i = 0;
    for(let key in this.element.selectOptionGroup) {
      i++;
      for (let key2 in this.element.selectOptionGroup[key].selectOption) {
        if (i == prev) {
          option = this.element.selectOptionGroup[key].selectOption[key2];
          this.element.selectOptionGroup[key].selectOption.splice(key2, 1);
        }
        i++;
      }
    }
    i = 0;
    for(let key in this.element.selectOptionGroup) {
      i++;
      for (let key2 in this.element.selectOptionGroup[key].selectOption) {
        if (i == next) {
          this.element.selectOptionGroup[key].selectOption.splice(key2, 0, option);
        }
        i++;
      }
      if (i == next) {
        this.element.selectOptionGroup[key].selectOption.push(option);
      }
    }
  }
}
